import { useEffect, useState } from "react";
import { BiCategory } from "react-icons/bi";

import { CourseCard } from "components/CourseCard";

import { Container } from "./styles";
import { CoursesType } from "../../types/Courses";
import courseHttp from "../../repositores/course";
import "react-loading-skeleton/dist/skeleton.css";
import SkeletonVideoCard from "../../components/SkeletonVideoCard";
import { Helmet } from "react-helmet-async";
import useAuth from "../../hooks/useAuth";

export const Courses = (): JSX.Element => {
  const [courses, setCourses] = useState<CoursesType[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const { user } = useAuth();

  async function getCourses() {
    setLoading(true);
    try {
      const { data } = await courseHttp.subscritos();
      setCourses(data.data);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getCourses();
  }, []);

  return (
    <Container>
      <Helmet title={"Cursos"} />
      <header className="subheader">
        <div className="container-fluid">
          <div className="subheader-container">
            <h1>
              <BiCategory /> Meus Cursos
            </h1>
          </div>
        </div>
      </header>
      <section className="section">
        <div className="container-fluid">
          <div className="courses">
            {loading ? (
              <SkeletonVideoCard count={8} height={650} />
            ) : (
              <>
                {courses.length > 0 ? (
                  courses.map((course) => (
                    <CourseCard key={course.id} {...course} inactive={false} />
                  ))
                ) : (
                  <p>Nenhum curso encontrado. :-/</p>
                )}
              </>
            )}
            {/*{loading && <SkeletonVideoCard count={8} height={650} />}*/}
            {/*{!loading && courses.length > 0*/}
            {/*  ? courses.map((course) => (*/}
            {/*      <CourseCard key={course.id} {...course} inactive={false} />*/}
            {/*    ))*/}
            {/*  : "Nenhum curso encontrado. :-/"}*/}
          </div>
        </div>
      </section>
    </Container>
  );
};
