import { useNavigate } from "react-router-dom";
import { Container } from "./styles";
import { CoursesType } from "../../types/Courses";
import clsx from "clsx";

interface CourseCardType extends CoursesType {
  inactive?: boolean;
}

export const CourseCard = ({
  id,
  title,
  thumb,
  inactive = false,
}: CourseCardType) => {
  const navigate = useNavigate();

  return (
    <Container key={id}>
      <button
        type="button"
        onClick={() => navigate(`/cursos/details/${id}`)}
        className="card"
      >
        <picture>
          <img
            className={clsx({ inactive: inactive })}
            src={`${process.env.REACT_APP_URL_SPACE}/${thumb}`}
            alt={title}
            loading="lazy"
          />
        </picture>

        {/*<div className="info">*/}
        {/*  <h4>{title}</h4>*/}
        {/*  <p>{description}</p>*/}
        {/*</div>*/}
      </button>
    </Container>
  );
};
